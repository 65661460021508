@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
  padding: 0;

  /** background image */
  background-image: url("./images/bg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Aloevera';
  src: local('Aloevera-OVoWO'), url('./fonts/Aloevera-OVoWO.ttf') format('truetype');
}

h1 {
  font-family: 'Aloevera';

  color: rgb(0, 0, 0);
  text-align: center;
}

p {
  font-family: 'Aloevera';
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  text-align: center;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Set background image and height of main element */
main {
  background-image: url("./images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100% - 60px);
  /* subtract header height */
}

header h1 {
  font-size: 2rem;
}

/* Add some padding to the content */
main>* {
  padding: 1rem;
}

/* make all images not draggable */
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@media screen and (max-width: 640px) {
  .container {
    width: 100%;
    height: 100%;
  }

  .grid {
    grid-template-columns: 1fr;
  }
  
  .card-wrapper {
    padding-bottom: 180%;
  }

  .card-wrapper p{
    font-size: 1.5rem;
  }

  .card-wrapper a{
    font-size: 1.5rem;
    margin-top: 6rem;
  }
  
  .card {
    padding: 1rem;
  }
  
  .card-title {
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
  
  .card-body {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  .card-footer {
    font-size: 0.875rem;
    line-height: 1.5;
  }
}


